import { CardTooltip } from "components/ui/card-tooltip";
import './card.css'

export default function Card({ title, helpText, chart}) {
    return (
        <div className="chart-card" >
            <div className="card-top">
                <div className="card-title">{title}</div>
                <CardTooltip helpText={helpText} />
            </div>
            <div className="card-bottom" >
                {chart}
            </div>
        </div>
    )
}
