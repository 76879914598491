import React from 'react';
import './modal.css';
import { SignOutButton } from "@clerk/clerk-react";
import { Link } from 'react-router-dom';

const Modal = ({ organization, user }) => {
  return (
    <div className="modal-overlay">
        <div className="modal-content">
            <div className="user">
                <div style={{fontWeight:700}}>{user.fullName}</div>
                <div>{organization.name}</div>
            </div>
            <hr></hr>
            <p><Link to="/select-workspace" className="link-style">Switch Organization</Link></p>
            <hr></hr>
            <SignOutButton> 
                <p>Sign out</p> 
            </SignOutButton>
        </div>
    </div>
  );
};

export default Modal;