import { useEffect, useState } from 'react';
import { CgMoreR } from "react-icons/cg";
import useFetch from '../../../utils/Clerk';
import { FaRegWindowClose, FaArchive } from "react-icons/fa";
import { useUsers, useArchive } from '../../../contexts/users-context';
const back_url = process.env.REACT_APP_API_URL;

export default function ArchiveModal ({ userId, isArchived, activeOrganization })  {
    const {archiveModal, setArchiveModal} = useArchive();
    const toggleModal = () => setArchiveModal(!archiveModal);
    useEffect(() => {
        console.log(isArchived)
    },[isArchived])

    return (
        <>
        <CgMoreR onClick={toggleModal} className="icon" />
        {archiveModal &&
        <div className="modal">
            {console.log("Rendered!")}
            <div className="users-overlay">
                <div className="users-modal-content">
                <div className='buttons-container'>
                <div className='users-card'>
                    <ArchiveContent
                    userId={userId}
                    isArchived={isArchived}
                    activeOrganization={activeOrganization}
                    />
                </div>
                </div>
                    <FaRegWindowClose onClick={toggleModal} className="close-modal" />
                </div>
            </div>
        </div>
        }
        </>
    )
}

function ArchiveContent({userId, isArchived, activeOrganization}) {
    const {setUsers} = useUsers();
    const {setArchiveModal} = useArchive();
    const [loading, setLoading] = useState(false)
    const fetch = useFetch();

    function fetchUsers() {
        fetch(`${back_url}/v1/organizations/${activeOrganization}/users`)
        .then(data => {
        setUsers(data.data);
        setArchiveModal(false)
        console.log("All set!")
        })
        .catch(error => console.error('Error fetching users:', error))
    }
    function toggleArchive() {
        setLoading(true)
        fetch(`${back_url}/v1/organizations/${activeOrganization}/users/${userId}`, {
            method: 'PUT',
            body: JSON.stringify([{ key: 'is_archived', value: !isArchived }])
        })
            .then(data => {
                fetchUsers()
                console.log("updated archive")
            })
            .catch(error => console.error('Error archiving user:', error))
    }
    return (
        <div style={{padding:'0.5rem'}}>
            {loading ? <div className='archive-button'>Saving...</div>
            :
            <div className='archive-button' onClick={toggleArchive}>
                {isArchived ? <div>Remove from archive</div> : <div>Archive</div> }
                <FaArchive className='icon' />
            </div>
            }

        </div>
    )
}
