import Card from "../../../shared/card/card"
import DoughnutChart from "../../../shared/charts/radial/doughnut-chart"
import { InfoBlock } from "./infos"

export default function Counter({ name, title, helptext, color, totalCount, categoryCount, label, question, answerLines, criteria }) {
    return (
      <Card
        title={<h3>{title}</h3>}
        helpText={helptext}
        chart={
          <>
            <DoughnutChart
              data={{
                category: name,
                score: categoryCount,
                total: totalCount,
                color: color,
                label: label
              }}
            />
            <InfoBlock
              question={question}
              answer={answerLines.map((line, index) => (
                <div key={index}>{line}</div>
              ))}
              color={color}
              criteria={criteria}
            />
          </>
        }
      />
    );
  }  

// // Utility function to split answer into lines
// function splitAnswerIntoLines(answer) {
//     return answer.split('\n');
// }

// export function Counter({employeeesWeekData, category}) {

//     const [totalCount, setTotalCount] = useState(0)
//     const [categoryCount, setCategoryCount] = useState(0)

//     // Prepare answer lines
//     const answerLines = splitAnswerIntoLines(category.answer);

//     useEffect(() => {
//         const filteredEmployees = employeeesWeekData.filter(employee => employee[`${category.name}`]);
//         setCategoryCount(filteredEmployees.length)
//         setTotalCount(employeeesWeekData.length)
//     },[employeeesWeekData, category.name])

//     return (
//         <Card 
//             title={<h3>{category.title}</h3>} 
//             helpText={category.helpText}
//             chart={
//                 <>
//                     <DoughnutChart 
//                         data={{ 
//                             category: category.name,
//                             score: categoryCount, 
//                             total: totalCount,
//                             color: category.color, 
//                             label:category.label }} 
//                     />
//                     <InfoBlock  
//                         question={category.question}
//                         answer={answerLines.map((line, index) => (
//                             <div key={index}>
//                                 {line}
//                             </div>
//                         ))}
//                         color={category.color}
//                         criteria={category.criteria}
//                     />
//                 </>  
//             }
//         />
//     )
// }
