import { cn } from "lib/utils"

function Skeleton({
  className,
  ...props
}) {
  return (<div className={cn("animate-pulse rounded-md bg-slate-300", className)} {...props} />);
}

function LoadingCards() {
  return (
    <>
    {[...Array(5)].map(() => (
    <div className="flex flex-col space-y-3">
      <Skeleton className="h-32 w-72 rounded-xl" />
      <div className="space-y-2">
        <Skeleton className="h-4 w-[250px]" />
        <Skeleton className="h-4 w-[200px]" />
      </div>
    </div>
    )
     )}
    </>
  )
}

export { LoadingCards, Skeleton }
