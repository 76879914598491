import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '@clerk/clerk-react';
import RestrictedAccessPage from '../pages/restricted-access/restricted-access';

const AdminRoute = ({ element }) => {
  const { orgRole } = useAuth();

  // Check if the user is an admin
  const isAdmin = (orgRole === 'admin');

  return isAdmin ? (
    <> { element } </>
  ) : (
    < Navigate element={<RestrictedAccessPage />} />
  );
};

export default AdminRoute;