import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "components/ui/tooltip"
import { HiOutlineInformationCircle } from "react-icons/hi"

export function CardTooltip({ helpText }) {
    return (
        <TooltipProvider>
            <Tooltip>
                <TooltipTrigger>
                    <HiOutlineInformationCircle color="#d4d4d4" size={24} />
                </TooltipTrigger>
                <TooltipContent>
                    {helpText}
                </TooltipContent>
            </Tooltip>
        </TooltipProvider>
    )
}
