import './users.css'
import { useState, useEffect, useCallback } from 'react';
import { UserCard } from './user-card/user-card';
import { ManageTeamsModal } from './manage-teams-modal/manage-teams-modal';
import { IoSettingsOutline } from "react-icons/io5";
import useFetch from '../../utils/Clerk';
import { useOrganization } from '@clerk/clerk-react';
import { useUsers } from '../../contexts/users-context';
import { LoadingCards } from 'components/ui/skeleton';

const back_url = process.env.REACT_APP_API_URL;

export default function Users() {
    const fetch = useFetch();
    const { organization } = useOrganization();
    const activeOrganization = organization.id;
    const [loading, setLoading] = useState(true);
    const {teamsModal, setTeamsModal} = useUsers();
    const {setTeams} = useUsers();
    const [archiveMode, setArchiveMode] = useState(false);
    const {users, setUsers} = useUsers();
    
    function showArchivedUsers() {
        setArchiveMode(!archiveMode);
        fetchArchivedUsers();
    }

    function showAllUsers() {
        setArchiveMode(false);
        fetchUsers();
    }
    
    function fetchArchivedUsers() {
        setLoading(true)
        fetch(`${back_url}/v1/organizations/${activeOrganization}/users/archived`)
        .then(data => {
        setUsers(data.data);
        setLoading(false);
        })
        .catch(error => console.error('Error fetching users:', error))
    }

    const fetchUsers = useCallback(() => {
        setLoading(true);
        fetch(`${back_url}/v1/organizations/${activeOrganization}/users`)
            .then(data => {
                setUsers(data.data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching users:', error);
                setLoading(false);
            });
    }, [fetch, activeOrganization, setUsers]);

    const getTeams = useCallback(() => {
        setLoading(true);
        fetch(`${back_url}/v1/organizations/${activeOrganization}/teams`)
            .then(data => {
                setTeams(data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching teams:', error);
                setLoading(false);
            });
    }, [fetch, activeOrganization, setTeams]);

    useEffect(() => {
        fetchUsers();
        getTeams();
    }, [fetchUsers, getTeams]);


    return (
        <>
            <h2 className="bar-title">Users</h2>
            <hr className="divider" />
            <div className='buttons-container'>
                {archiveMode ?
                <div className='buttons' onClick={showAllUsers}>
                    Hide Archived Users
                </div>
                :
                <div className='buttons' onClick={showArchivedUsers}>
                    Show Archived Users
                </div>
                }
                
                <div className='buttons' onClick={() => setTeamsModal(!teamsModal)}>
                    <IoSettingsOutline />Configure teams
                </div>
            </div>
            
            {teamsModal && <ManageTeamsModal activeOrganization={activeOrganization}/>}
            <div className="users-grid">
                {loading ? <LoadingCards />
                    :
                    <>
                        {users.map(user => (
                            <UserCard key={user.id} user={user} activeOrganization={activeOrganization} />
                        ))}
                    </>
                }
            </div>
        </>
    );
}
