import React from 'react';
import './restricted-access.css';

const RestrictedAccessPage = () => {

  return (
    <div className="RestrictedAccessPage">
      <p>You do not have permission to access this page.</p>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
        <path fill-rule="evenodd" d="M12 1.5a5.25 5.25 0 00-5.25 5.25v3a3 3 0 00-3 3v6.75a3 3 0 003 3h10.5a3 3 0 003-3v-6.75a3 3 0 00-3-3v-3c0-2.9-2.35-5.25-5.25-5.25zm3.75 8.25v-3a3.75 3.75 0 10-7.5 0v3h7.5z" clip-rule="evenodd" />
      </svg>
      <h2>Restricted Access</h2> 
    </div>
  );
};

export default RestrictedAccessPage;
