import { BsPieChart, BsPeople } from "react-icons/bs";
import { PiPlugs } from "react-icons/pi";

export const SidebarData = () => {

  // Default menu items
  const iconStyles = { color: "black" };
  const menuItems = [
    {
      subNav: [
        {
          title: 'Dashboard',
          path: './dashboard',
          icon: <BsPieChart style={iconStyles}/>
        },
        {
          title: 'Teams and Users',
          path: './users',
          icon: <BsPeople style={iconStyles}/>
        },
        {
          title: 'Integrations',
          path: './integrations',
          icon: <PiPlugs style={iconStyles}/>
        }
      ]
    },
  ];

  return menuItems;
};