import React, { useEffect, useRef } from "react";
import { Chart } from 'chart.js';
import "./doughnut-chart.css";
import "../../../../../index.css";

// const getLevelColor = (level) => {
//     if (level === 1) {
//         return '--red';
//     } else if (level === 2) {
//         return '--yellow';
//     } else if (level === 3) {
//         return '--green';
//     } else {
//         return '--blue';
//     }
// };

const DoughnutChart = ({ data }) => {
    const chartRef = useRef(null);

    useEffect(() => {
        if (chartRef.current && data) {

            const { score, category, label } = data;
            //const { color, label } = getColorAndLabel(level);
            const color = data.color
            const subtext = label
            const donut_ctx = chartRef.current.getContext("2d");

            const myChart = new Chart(donut_ctx, {
                type: 'doughnut',
                data: {
                    labels: ["score"],
                    datasets: [
                        {
                            label: "latest score",
                            data: [ score, data.total-score ],
                            backgroundColor: [ color, "#f4f4f9" ],
                            borderColor: [ color, "#f4f4f9" ],
                            borderRadius: 100,
                        },
                    ],
                },
                options: {
                    cutoutPercentage: 80,
                    rotation: Math.PI * 0.65,
                    circumference: Math.PI * 1.7,
                    tooltips: false,
                    hover: false,
                    animation: {
                        animateRotate:false,
                    },
                    legend: {
                        display: false,
                    },
                    title: {
                        display: false,
                    },
                    layout: {
                        autoPadding:false,
                    },
                    aspectRatio: 1.5,
                },
            });

            //Display the averageScore in the middle of the chart.
            //See: https://stackoverflow.com/a/34947440
            Chart.pluginService.register({
                beforeDraw: function (chart) {
                    if (chart.config.type === "doughnut" && chart.canvas.id === `myChart${category}`) {
                        console.log(chart.config.type)
                        var width = chart.chart.width,
                            height = chart.chart.height,
                            ctx = chart.chart.ctx;
                        //clean up the old text, if there is any.
                        ctx.clearRect(0, 0, width, height);
                        ctx.restore();
                        //Draw average score.
                        var latestScoreFontSize = (height / 50).toFixed(2);
                        ctx.font = "bold " + latestScoreFontSize + "em sans-serif";
                        ctx.textBaseline = "middle";
                        var textWidth = Math.max(ctx.measureText(score).width);
                        var textX = Math.round((width - textWidth) / 2);
                        var textY = height / 2;
                        ctx.fillText(score, textX, textY);

                        // Draw score label under the average score.
                        var scoreLabelFontSize = (height / 200).toFixed(2);
                        ctx.font = "lighter " + scoreLabelFontSize + "em sans-serif";
                        ctx.textBaseline = "middle";
                        var labelWidth = Math.max(ctx.measureText(subtext).width);
                        var labelX = Math.round((width - labelWidth) / 2);
                        var labelY = height / 1.5;
                        ctx.fillText(label, labelX, labelY + scoreLabelFontSize * 1.5);

                        ctx.save();
                    }
                }
            });

            // Save the chart instance to the ref
            chartRef.current.chart = myChart;

            // Clean up previous chart (if needed)
            return () => {
                myChart.destroy();
            };
        }
    }, [data]);

    return (
        <div className="doughnutchart-wrapper" >
            <canvas ref={chartRef} id={`myChart${data.category}`}></canvas>
        </div>
    );
};

export default DoughnutChart;
