import { OrganizationList } from "@clerk/clerk-react";
import "./workspace-switch.css"

export default function WorkspaceSwitchPage () {
    return (
      <div className="orgSwitch">
        <OrganizationList
          hidePersonal={true}
          afterSelectOrganizationUrl={ () => "/" }
        />
      </div> 
    );
  };