import React from "react";
import SimplePage from "../../layouts/SimplePage";

export default function TCPage() {
    return(
        <div style={SimplePage}>
            <h1>Terms and Conditions</h1>
            <p> Paragraph 1</p>
            <p> Paragraph 2</p>
            <p> Paragraph 3</p>
        </div>
    )
}