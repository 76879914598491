import {useState} from 'react';

export const InfoBlock = ({ question, answer, color, criteria }) => {
    const [showCriteria, setShowCriteria] = useState(false);
    
    const containerStyle = {
      margin: '2rem',
      padding: '0.75rem',
      flex: '1',
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
  
      backgroundColor: '#f4f4f9',
      borderRadius: '0.5rem',
      fontSize: '0.75rem'
  
    };
  
    const titleStyle = {
      display: 'inline-block',
      paddingInline: '0.5rem',
      paddingBlock: '0.25rem',
  
      fontWeight: '500',
      color: '#fff',
      backgroundColor: color,
      borderRadius: '0.5rem',
    };
  
    const clickableStyle = {
      fontSize: '0.6rem',
      fontWeight: '500',
      color: '#368CE1',
      textDecoration: 'underline',
      cursor: 'pointer',
      alignSelf: 'flex-start'
    };
  
    const handleClick = () => {
      setShowCriteria(!showCriteria);
    };
  
    return (
      <div style={containerStyle}>
        <div style={{flex:1, display: 'flex', flexDirection: 'column', gap: '0.5rem'}}>
          <div>
            <div style={titleStyle}>{question}</div>
          </div>
          <div>{answer}</div>
        </div>
        <div style={{display: 'flex', flexDirection: 'column', gap: '0.5rem'}}>
          <div style={clickableStyle} onClick={handleClick}>
            {showCriteria? "HIDE CRITERIA": "SHOW CRITERIA"}
          </div>
          {showCriteria? <DisplayCriteria criteria= {criteria} />:<></>}
        </div>
      </div>
    );
  };

const DisplayCriteria = ({ criteria }) => {

  const operatorStyle = {
    color: '#368CE1'
  };

  const displayConditions = (conditions, operator) => {
    
    const conditionsListStyle = {
      padding: '0.25rem',
      display: 'flex',
      flexDirection: 'column',
      gap: '0.25rem',
  
      backgroundColor: '#fff',
      borderRadius: '0.5rem',
    };

    const conditionRowStyle = {
      display: 'flex',
      flexDirection: 'row',
      gap: '0.25rem',
      alignItems: 'center',
    };

    const conditionStyle = {
      padding: '0.25rem',
      borderRadius: '0.25rem',

      backgroundColor: '#f4f4f9'
    };

    if (conditions.length === 0) return null;

    return (
      <div style={conditionsListStyle} name="conditions-list">
        {
          conditions.map((condition, index) => (
            <div key={index} style={conditionRowStyle} name="condition-row">
              {index !== 0 && (
                <div style={operatorStyle} name="operator">
                  {operator}
                </div>
              )}
              <div style={conditionStyle} name="condition"> {condition} </div>
            </div>
          ))
        }
      </div>
    );
  };

  const displayAllConditions = () => {

    const criteriaStyle = {
      display: 'flex',
      flexDirection: 'column',
      gap: '0.5rem',

      fontSize: '0.6rem'
    };

    const { 0: ANDConditions, 1: ORConditions } = criteria;
    const ANDConditionsDisplay = displayConditions(ANDConditions, 'AND');
    const ORConditionsDisplay = displayConditions(ORConditions, 'OR');

    return (
      <div style={criteriaStyle}>
        {ANDConditionsDisplay}
        {ANDConditionsDisplay 
        && ORConditionsDisplay 
        && <div style={operatorStyle} name="operator">AND</div>}
        {ORConditionsDisplay}
      </div>
    );
  };

  return <div>{displayAllConditions()}</div>;
};