import './full-page.css';

const FullPageLayout = ({ content }) => {
  return (
    <div className="full-page-layout">
      {content}
    </div>
  );
};

export default FullPageLayout;
