import './dashboard.css'
import { useOrganization } from "@clerk/clerk-react";

import { MainDashboard } from "./main-dashboard/main-dashboard";

export default function Dashboard() {
  
  const { organization } = useOrganization();
  const activeOrganization = organization.id;

  const renderTabContent = (activeOrganization) => {
    return <MainDashboard activeOrganization={activeOrganization} />
  };

  return (
    <div className="content">
      <div className="bar-container">
        <h2 className="bar-title">Dashboard</h2>
      </div>
      <hr className="divider"></hr>
      
      <div className="tab-content">
        {renderTabContent(activeOrganization)}
      </div>
    </div>
  );
}
