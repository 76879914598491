import React from 'react';
import './install.css';

const InstallPage = () => {

  const back_url = process.env.REACT_APP_API_URL;

  return (
    <div className="InstallPage">
      <h2>Let's installation page</h2>
      <a href= {`${back_url}/slack/install`}>
        <img alt="Add to Slack" height="40" width="139" src="https://platform.slack-edge.com/img/add_to_slack.png" srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x" />
      </a> 
      <p> By installing the app, you will be accepting our <a href='/terms-and-conditions'>terms of service</a> and <a href='/privacy'>privacy conditions</a>.</p>
    </div>
  );
};

export default InstallPage;
