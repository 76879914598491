import React, { useEffect, useRef } from "react";
import { Chart } from "chart.js";

export default function LineChart({ data, label, x, y, color }) {
    const chartRef = useRef(null);
    const chartInstance = useRef(null);

    useEffect(() => {
        if (chartRef.current && data && data.length > 0) {

            // Destroy existing chart instance if it exists
            if (chartInstance.current) {
                chartInstance.current.destroy();
            }

            const time = data.map(item => item[x]);
            const value = data.map(item => item[y]);

            const chart_ctx = chartRef.current.getContext("2d");

            // const fill_gradient = chart_ctx.createLinearGradient(0, chartRef.current.height, 0, 0);
            // fill_gradient.addColorStop(1, "#378CE0");
            // fill_gradient.addColorStop(0, "#378CE000");

            // const border_gradient = chart_ctx.createLinearGradient(0, 300, 0, 0);
            // border_gradient.addColorStop(0, "#378CE0");
            // border_gradient.addColorStop(1, "#62D58A");

            chartInstance.current = new Chart(chart_ctx, {
                type: "line",
                data: {
                    labels: time,
                    datasets: [
                        {
                            label: label,
                            data: value,

                            borderWidth: 4,
                            borderColor: color,    
                            cubicInterpolationMode: 'monotone',
                            tension: 0.5,
                            fill: true,
                            backgroundColor: `${color}25`,
                            pointStyle: 'circle',
                            pointRadius: 0,
                            pointHoverRadius: 8,
                            pointHoverBorderColor: color,
                        },
                    ],
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    hover: {
                        intersect: false,
                        mode: 'index',
                        axis: 'x'
                    },
                    tooltips: {
                        intersect: false,
                        mode: 'index',
                        axis: 'x',
                        callbacks: {
                            title: function(tooltipItems, data) {
                                const dateLabel = data.labels[tooltipItems[0].index];
                                return `Week of ${dateLabel}`;
                            },
                            label: function(tooltipItem, data) {
                                const value = tooltipItem.yLabel;
                                const label = data.datasets[tooltipItem.datasetIndex].label;
                                return `${value}${label}`;
                            }
                        },
                        position: 'average',
                        boxPadding: 16,
                        displayColors: false,
                        caretPadding: 24,
                        caretSize: 0,
                        backgroundColor: '#ffffff50',
                        titleFontColor: '#000',
                        titleFontStyle: 'normal',
                        bodyFontColor: '#000',
                        bodyFontStyle: 'bold',
                        bodyFontSize: 24,
                    },
                    legend: {
                        display: false,
                    },
                    layout: {
                        padding: {
                          top: 0,
                          left:0,
                        }
                    },
                    scales: {
                        xAxes: [
                            {
                                time: {
                                    unit: 'month',
                                    displayFormats: {
                                        month: 'MMM YYYY'
                                    },
                                },
                                ticks: {
                                    maxTicksLimit: 3,
                                    display: false,
                                    autoSkip: true,
                                    maxRotation: 0,
                                    minRotation: 0,
                                    padding: 8,
                                },
                                gridLines: {
                                    display: false,
                                    drawBorder: false,
                                },
                                type: 'time',
                                position: "top",
                                afterFit: (axis) => {
                                    axis.paddingLeft = 0;
                                    axis.paddingRight = 64;
                                }
                            },
                        ],
                        yAxes: [
                            {
                                grace:1,
                                ticks: {
                                    beginAtZero: true,
                                },
                                display: false,
                            },
                        ],
                    },
                },
            });

            return () => {
                if (chartInstance.current) {
                    chartInstance.current.destroy();
                    chartInstance.current = null;
                }
            };
        }
    }, [data, label, x, y, color]);

    return (
        <div id="wrapper" style={{ position: 'relative', height: '100%' }}>
            <canvas ref={chartRef} id="myChart" ></canvas>
        </div>
        
    )
}
