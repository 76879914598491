export default function Table({ header, rows }) {
    return (
        <>
            <table className="isolation-table">
                <thead >
                    <tr className="head">
                        {header.map((column, index) => (
                            <th key={index}>{column}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {rows.map((row, rowIndex) => (
                        <tr key={rowIndex} className="row" >
                            {Object.values(row).map((cell, cellIndex) => (
                                <td key={cellIndex} className={`row ${rowIndex % 2 === 0 ? "gray-background" : "white-row"}`}>{cell}</td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    );
}