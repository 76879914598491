import React, { useState, useEffect, useRef, useCallback } from "react";
import './messages.css'
import { useOrganization } from "@clerk/clerk-react";
import useFetch from "../../utils/Clerk";
import { useAuth } from "@clerk/clerk-react";

const back_url = process.env.REACT_APP_API_URL;

export default function Messages() {
  const { organization } = useOrganization();
  const fetch = useFetch()

  const [activeOrganization, setActiveOrganization] = useState(organization.id);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setActiveOrganization(organization.id)
  }, [organization])

  useEffect(() => {
    const fetchUsersData = () => {
      fetch(`${back_url}/v1/organizations/${activeOrganization}/messages/users`)
        .then(data => {
          setLoading(false);
          setUsers(data);
        })
        .catch(error => console.error('Error fetching users:', error));
    };

    fetchUsersData();
  }, [activeOrganization, fetch]);

  return (
    loading ? <p>Loading...</p>
      :
      <>
        <div className="left-nav-layout">
          <div className="message-right">
            {selectedUser && <ChatSection
              user={selectedUser}
              organizationId={activeOrganization}
            />}
          </div>
          <div className="message-left">
            <h2>Users</h2>
            <div className="menu-entry">
              {users.map((user) => (
                <div
                  className="username"
                  key={user.id}
                  onClick={() => setSelectedUser(user)}>
                  {user.name}
                </div>
              ))}
            </div>
          </div>
        </div>
      </>
  );
}

function ChatSection({ user, organizationId }) {
  const { getToken } = useAuth()
  const [textInput, setTextInput] = useState("");
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const chatRef = useRef(null);

  useEffect(() => {
    // Scroll to the bottom when rendering the messages.
    if (chatRef.current) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
  }, [messages]);

  const handleChange = (event) => {
    setTextInput(event.target.value);
  }
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const token = await getToken()
      const response = await fetch(`${back_url}/v1/organizations/${organizationId}/dms/${user.id}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
        body: JSON.stringify({ text: textInput }),
      });

      if (!response.ok) {
        console.error('Error:', response.statusText);
      } else {
        console.log('Message sent successfully');
        setTextInput("");
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }
  const getMessages = useCallback(async () => {
    setLoading(true);
    try {
      const token = await getToken()
      const response = await fetch(`${back_url}/v1/organizations/${organizationId}/dm-list/${user.id}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
      });

      if (!response.ok) {
        console.error('Error:', response.statusText);
      } else {
        let data = await response.json();
        setMessages(data.messages)
        setLoading(false);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }, [user, getToken, organizationId])

  useEffect(() => {
    getMessages();
  }, [getMessages])

  return (
    <section className="msger">
      <header class="msger-header">
        <div class="msger-header-title">
          {user?.name}
        </div>
      </header>
      {loading ? <p>Loading...</p> :
        messages && messages.length > 0 && (
          <main ref={chatRef} className="msger-chat" style={{ overflowY: 'auto' }}>
            {messages?.slice().reverse().map((message) => (
              message.user === user.id ?
                (
                  <div className="msg right-msg">
                    <div
                      className="msg-img"
                      //style={{ backgroundImage: `url(${user.image_url})` }}
                    ></div>

                    <div className="msg-bubble">
                      <div className="msg-info">
                        <div className="msg-info-name">{user.name}</div>
                        <div className="msg-info-time">12:46</div>
                      </div>

                      <div className="msg-text">
                        {message.text}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="msg left-msg">
                    <div
                      className="msg-img"
                      style={{ backgroundImage: `url(${message.bot_profile?.icons?.image_48})` }}
                    ></div>

                    <div className="msg-bubble">
                      <div className="msg-info">
                        <div className="msg-info-name">{message.bot_profile?.name}</div>
                        <div className="msg-info-time">12:45</div>
                      </div>

                      <div className="msg-text">
                        {message.text}
                      </div>
                    </div>
                  </div>
                )
            ))}

          </main>
        )
      }

      <form className="msger-inputarea">
        <input type="text" className="msger-input" value={textInput} onChange={handleChange} placeholder="Enter your message..." />
        <button type="submit" onClick={handleSubmit} className="msger-send-btn">
          Send
        </button>
      </form>
    </section>

  )
}
