import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/ui/select"

export default function SingleSelectDropdown({options, activeOption, changeHandler}) {
    const keys = Object.keys(options);
  
    const handleOptionChange = (value) => {
      changeHandler(value);
    };
  
    return (
      <Select onValueChange={handleOptionChange}>
        <SelectTrigger>
          <SelectValue placeholder={options[activeOption]} />
        </SelectTrigger>
        <SelectContent>
          {keys.map((key) => (
            <SelectItem value={key}>{options[key]}</SelectItem>
          ))}
        </SelectContent>
      </Select>
    )
  }