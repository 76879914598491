import React from 'react';
import './left-nav.css';

const LeftNavLayout = ({ left, right }) => {
  return (
    <div className="left-nav-layout">
      <div className="left">
        {left}
      </div>
      <div className="right">
        {right}
      </div>
    </div>
  );
};

export default LeftNavLayout;
