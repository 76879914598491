import './manage-teams-modal.css'
import { GoPencil, GoCheck } from "react-icons/go";
import { FaRegTrashAlt } from "react-icons/fa";
import { AiOutlinePlus } from "react-icons/ai";
import Swal from 'sweetalert2';
import { showSuccessAlert } from "../../shared/alerts/alert";
import useFetch from "../../../utils/Clerk";
import { useState, useEffect, useCallback } from 'react';
import { useUsers } from '../../../contexts/users-context';
const back_url = process.env.REACT_APP_API_URL;

const teamColors = ['#FFC329', '#9747FF', '#ED542A', '#368CE1'];

export const ManageTeamsModal = ({ activeOrganization }) => {
    const fetch = useFetch();
    const [loading, setLoading] = useState(true);
    const {teams, setTeams} = useUsers();
    const [newTeam, setNewTeam] = useState("");

    const getTeams = useCallback ((activeOrganization) => {
        setLoading(true)
        fetch(`${back_url}/v1/organizations/${activeOrganization}/teams`)
            .then(data => {
                setTeams(data);
                setLoading(false);
            })
            .catch(error => console.error('Error fetching teams:', error))
    },[fetch, setTeams]);

    useEffect(() => {
        getTeams(activeOrganization);
    }, [activeOrganization, getTeams]);

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && newTeam) {
            addNewTeam(newTeam);
        }
    };
    const handleChange = (e) => {
        setNewTeam(e.target.value)
    }
    const handleSubmit = (e) => {
        if (newTeam) addNewTeam(newTeam);
    }
    const addNewTeam = async (teamName) => {
        try {
            await fetch(`${back_url}/v1/organizations/${activeOrganization}/teams`, {
                method: 'POST',
                body: JSON.stringify({ name: teamName }),
            })
            getTeams(activeOrganization);
            setNewTeam("");
        }
        catch (error) {
            console.error('Error fetching teams:', error)
        }
    }


    if (loading) return <p className='buttons-container'>Loading...</p>
    return (
        <div className='buttons-container'>
            <div className='users-card'>
                {teams.map(team => (
                    <TeamSection team={team} activeOrganization={activeOrganization} />
                ))}

                <div className='input-wrapper'>
                    <AiOutlinePlus onClick={handleSubmit} className="icon" />
                    <input
                    type="text"
                    value={newTeam}
                    onKeyDown={handleKeyDown}
                    onChange={handleChange}
                    className="new-team-input"
                    placeholder="Create a new team"
                    />
                </div>
            </div>
        </div>
    )
}


function TeamSection({ team, activeOrganization }) {
    const { teams, setTeams } = useUsers();
    const [edit, setEdit] = useState(false);
    const [updatedValue, setUpdatedValue] = useState(team.name); // State to store updated value
    const fetch = useFetch();
    const [teamColor, setTeamColor] = useState('');

    const handleDeleteTeam = async (team_id) => {
        try {
            await fetch(`${back_url}/v1/organizations/${activeOrganization}/teams/${team_id}`, {
                method: 'DELETE'
            });
            setTeams(teams.filter((t) => t.id !== team_id));
            showSuccessAlert('Team deleted successfully');
        } catch (error) {
            console.error('Error deleting team:', error);
        }
    };

    const handleUpdateTeam = async () => {
        try {
            const updatedTeam = { ...team, name: updatedValue }; // Create updated team object
            await fetch(`${back_url}/v1/organizations/${activeOrganization}/teams/${team.id}`, {
                method: 'PUT',
                body: JSON.stringify({ name: updatedTeam })
            });
            // Update teams state with the updated team
            setTeams(teams.map(t => (t.id === updatedTeam.id ? updatedTeam : t)));
            setEdit(false); // Exit edit mode
            showSuccessAlert('Team updated successfully');
        } catch (error) {
            console.error('Error updating team:', error);
        }
    };

    const showDeleteAlert = (team_id) => {
        Swal.fire({
            title: 'Confirmation',
            text: 'Delete team?',
            icon: 'question',
            confirmButtonText: 'Delete',
        }).then((result) => {
            if (result.isConfirmed) {
                handleDeleteTeam(team_id);
            }
        });
    };
    const getRandomColor = () => {
        const randomIndex = Math.floor(Math.random() * teamColors.length);
        return teamColors[randomIndex];
    };

    useEffect(() => {
        setTeamColor(getRandomColor());
    }, [team])

    return (
        <div className="teams-container">
            {edit ? (
                <>
                    <input
                        type='text'
                        className="teams-label"
                        style={{ background: teamColor }}
                        placeholder={team.name}
                        value={updatedValue}
                        onChange={(e) => setUpdatedValue(e.target.value)}
                        autoFocus
                    />
                    <div className="item-container">
                        <GoCheck onClick={handleUpdateTeam} className="icon" />
                        <FaRegTrashAlt onClick={() => showDeleteAlert(team.id)} className="icon" />
                    </div>
                </>
            ) : (
                <>
                    <div className="teams-label" style={{ background: teamColor }}>{team.name}</div>
                    <div className="item-container">
                        <GoPencil onClick={() => setEdit(true)} className="icon" />
                        <FaRegTrashAlt onClick={() => showDeleteAlert(team.id)} className="icon" />
                    </div>
                </>
            )}
        </div>
    );
}
