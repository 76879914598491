export function getFormattedDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so we add 1
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}

export function getDateStringMonthsAgo(dateString, monthsAgo) {
    const date = getDateMonthsAgo(dateString, monthsAgo)
    return getFormattedDate(date);
}

export function getDateMonthsAgo(dateObject, monthsAgo) {
    const date = new Date(dateObject);
    date.setMonth(date.getMonth() - monthsAgo);
    return date;
}

export function getMondaysBetweenDates(startDate, endDate) {
    const mondays = [];
    
    let currentDate = startDate;
    while (currentDate <= endDate) {
        if (currentDate.getDay() === 1) { // getDay() returns 1 for Monday
            mondays.push(new Date(currentDate)); // Store a copy of the date
        }
        currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
    }
    
    return mondays;
}