import './integrations.css';
import React, { useEffect, useState, useCallback } from 'react';
import { atom, useAtom } from 'jotai'
import { useOrganization } from '@clerk/clerk-react';
import useFetch from '../../utils/Clerk';

import { BiLike } from "react-icons/bi";
import { FaCircle } from "react-icons/fa";
import { MdDownloadDone, MdDownload } from "react-icons/md";
// import { SettingsModal } from './settings/settings';
const back_url = process.env.REACT_APP_API_URL;

const VOTE_FORMSPARK_URL = "https://submit-form.com/cwnQyP5l8";

export const orgIntegrationsAtom = atom({});

export default function Integrations() {

    const { organization } = useOrganization();
    const activeOrganization = organization.id
    const [integrations, setIntegrations] = useState([]);
    const [orgIntegrations, setOrgIntegrations] = useAtom(orgIntegrationsAtom);
    const [loading, setLoading] = useState(true);
    const fetch = useFetch();

    const getIntegrations = useCallback(() => {
        fetch(`${back_url}/v1/integrations`)
            .then((response) => {
                setIntegrations(response.data);
            })
            .catch((error) => {
                console.log("Error fetching integrations", error)
            })
    }, [fetch])

    const getOrgIntegrations = useCallback(() => {
        fetch(`${back_url}/v1/organizations/${activeOrganization}/integrations`)
            .then((response) => {
                setOrgIntegrations(response.data.integrations);
            })
            .catch((error) => {
                console.log("Error fetching integrations", error)
            })
    }, [fetch, activeOrganization, setOrgIntegrations])

    useEffect(() => {
        getIntegrations();
        getOrgIntegrations();
        setLoading(false)
    }, [activeOrganization, getIntegrations, getOrgIntegrations])

    if (loading) return <p>Loading...</p>

    return (
        <>
            <h2 className="bar-title">Integrations</h2>
            <hr className="divider" />
            <div className="grid-container">
                {integrations.map((app) => (
                    <IntegrationsCard
                        key={app.id}
                        app={app}
                        installedVersion={orgIntegrations[app.app]}
                        activeOrganization={activeOrganization}
                    />
                ))}
            </div>
        </>
    )
}

function IntegrationsCard({ app, installedVersion, activeOrganization }) {

    return (
        <div className='integrations-card'>
            <div className='card-content'>
                <div className='row-flex'>
                    <img src={`/assets/${app.app}.svg`} alt={`${app.name} logo`} />
                    <div className='integration-title'>
                        <div>{app.app}</div>
                        <div className='status'>
                            {app.is_available ?
                                <>
                                    {installedVersion !== null ?
                                        <>
                                            <FaCircle size='8' color='#5BC45A' />
                                            <div className='status-text'>Active</div>
                                        </>
                                        :
                                        <>
                                            <FaCircle size='8' color='#676767' />
                                            <div className='status-text'>Inactive</div>
                                        </>
                                    }
                                </>
                                :
                                <div className='status-text'>Coming soon</div>
                            }
                        </div>
                    </div>
                </div>
                {/* If available show settings, do not otherwise
                {app.is_available && <SettingsModal installedVersion={installedVersion} activeOrganization={activeOrganization} />} */}
            </div>
            <hr className='divider'></hr>
            {/* Create a component for this button that takes: app + organization (does it has an integration from the corresponding app yet? if yes, is it the most recent version?) -> 'Vote' if app not available, 'Install' if available but not present in organization integrations, 'Update' if available installed but not up to date, 'Up-to-date' otherwise  */}
            <ActionButton app={app} installedVersion={installedVersion} />
        </div>
    )
}

function ActionButton({ app, installedVersion = null }) {

    const { organization } = useOrganization();

    function handleInstall() {
        const installUrl = `${back_url}/slack/install?organization=${organization.id}`;
        window.open(installUrl, '_blank');
    }

    function handleVote() {
        fetch(VOTE_FORMSPARK_URL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: JSON.stringify({
                "organization": organization.name,
                "app": app.app,
            }),
        });
        alert("Thank you for your feedback");
    };

    return (
        <>
            {app.is_available ? (
                installedVersion !== null ? (
                    installedVersion.version === app.latest_version ? (
                        <div className='update integration-button'>
                            <MdDownloadDone color='white' />
                            <div style={{ color: 'white', fontWeight: '600' }}>Up to date</div>
                        </div>
                    ) : (
                        <div className='update integration-button' onClick={handleInstall}>
                            <MdDownload color='white' />
                            <div style={{ color: 'white', fontWeight: '600' }}>Update</div>
                        </div>
                    )
                ) : (
                    <div className='update integration-button' onClick={handleInstall}>
                        <MdDownload color='white' />
                        <div style={{ color: 'white', fontWeight: '600' }}>Install</div>
                    </div>
                )
            ) : (
                <div className='vote integration-button' onClick={handleVote}>
                    <BiLike />
                    <div style={{ fontWeight: '600' }}>Vote for this</div>
                </div>
            )}
        </>
    );
}
