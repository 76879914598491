import Swal from "sweetalert2"

export const showSuccessAlert = (message) => {
    Swal.fire({
        title: "Done",
        text: message,
        icon:"success",
        confirmButtonText: "Done",
        })
}

export const showErrorAlert = (statusText) => {
    Swal.fire({
        title: "Error",
        text: statusText,
        icon:"error",
        })
}