import { useAuth } from '@clerk/clerk-react';
import { useCallback } from 'react';

export default function useFetch() {
  const { getToken } = useAuth();

  // Wrap your function with useCallback
  const authenticatedFetch = useCallback(async (...args) => {
    const token = await getToken();
    return fetch(args[0], {
      ...args[1],
      headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' }
    }).then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('Network response was not ok');
      }
    });
  }, [getToken]); // Dependency array

  return authenticatedFetch;
}