import { React, useState } from "react";
import './sidebar.css'
import Subnav from "../subnav/subnav.js"
import { SidebarData } from "../SidebarData";
import { useOrganization, useUser } from "@clerk/clerk-react";
import logo from '../../../logo.svg';
import Modal from './modal/modal';

const Sidebar = () => {
  const sidebarData = SidebarData();
  const { organization } = useOrganization();
  const orgImageUrl = organization.imageUrl
  const { user } = useUser();
  const userImageUrl =  user.imageUrl
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <nav className="SidebarNav">
      <div className="SidebarTop">
        <img src={logo} alt="Logo" style={{ width: '40px', height: '40px' }}/>
      </div>
      <div className="SidebarWrap">
        {sidebarData.map((item, index) => {
          return <Subnav item={item} key={index} />;
        })}
      </div>
      <div className="SidebarBottom">
        <div className="UserProfile" onClick={toggleModal}>
          <img src={userImageUrl} alt="User Profile" className="Image"/>
        </div>
        <div className="OrganizationSwitcher" onClick={toggleModal}>
          <img src={orgImageUrl} alt="Organization Logo" className="Image"/>
        </div>
        {showModal && <Modal organization={organization} user={user} onClose={toggleModal} />}
      </div>
    </nav>
  );
}

export default Sidebar;