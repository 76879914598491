import { useState, useEffect } from "react";
import { GoPencil } from "react-icons/go";
import './select-teams-modal.css';
import { FaRegWindowClose } from "react-icons/fa";
import useFetch from "../../../utils/Clerk";
import { useUsers } from "../../../contexts/users-context";
import { useUserTeams } from "../../../contexts/users-context";
import {MdSave} from "react-icons/md"
const back_url = process.env.REACT_APP_API_URL;


export const SelectTeamsModal = ({ userId, activeOrganization }) => {
    const [modal, setModal] = useState(false);
    const toggleModal = () => setModal(!modal);

    return (
        <>
        <GoPencil onClick={toggleModal} className="icon" />
        {modal &&
        <div className="modal">
            <div className="users-overlay">
                <div className="users-modal-content">
                <div className='buttons-container'>
                <div className='users-card'>
                    
                    <SelectTeamsContent userId={userId} activeOrganization={activeOrganization} toggleModal={toggleModal} />
                </div>
                </div>
                    <FaRegWindowClose onClick={toggleModal} className="close-modal" />
                </div>
            </div>
        </div>
        }
        </>
    )
}

function SelectTeamsContent({ userId, activeOrganization, toggleModal }) {
    const {userTeams, setUserTeams} = useUserTeams();
    const [selectedTeams, setSelectedTeams] = useState([]);
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const { teams } = useUsers();
    const fetch = useFetch();

    useEffect(() => {
        function getUserTeams() {
            setLoading(true);
            fetch(`${back_url}/v1/organizations/${activeOrganization}/users/${userId}/teams`)
                .then(data => {
                    setSelectedTeams(data.map(user_team => user_team.team_id));
                    setUserTeams(data.map(user_team => user_team.team_id))
                    setLoading(false);
                })
                .catch(error => console.error('Error fetching teams:', error));
        }
        if (userId) getUserTeams();
    }, [userId, activeOrganization, fetch, setUserTeams]);

    const handleTeamSelection = teamId => {
        setSelectedTeams(prevTeams => {
            if (prevTeams.includes(teamId)) {
                return prevTeams.filter(id => id !== teamId);
            } else {
                return [...prevTeams, teamId];
            }
        });
    };

    const handleSave = async () => {
        //Add teams that are not already exists on the database.
        const teamsToAdd = selectedTeams.filter(teamId => !userTeams.includes(teamId));
        //Remove teams that are on the database but not on the selected list.
        const teamsToRemove = userTeams.filter(teamId => !selectedTeams.includes(teamId));
        let data = [];
        setSaving(true);
        try {
            if (teamsToAdd.length) {
                data = await fetch(`${back_url}/v1/organizations/${activeOrganization}/users/${userId}/teams`, {
                    method: 'POST',
                    body: JSON.stringify({teams_ids: teamsToAdd})
                });
                console.log("Teams added!");
                
            }

            if (teamsToRemove.length) {
                data = await fetch(`${back_url}/v1/organizations/${activeOrganization}/users/${userId}/teams`, {
                    method: 'DELETE',
                    body: JSON.stringify({teams_ids: teamsToRemove})
                });
                console.log("Teams removed!");
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setSaving(false);
            setSelectedTeams(data.map((user_team) => user_team.team_id));
            setUserTeams(data.map((user_team) => user_team.team_id))
            //Close the modal when the saving is done.
            toggleModal(false)
        }
    };

    if (loading) return <p>Loading...</p>;
    return (
        <>
            {teams.map(team => (
                <div className="teams-container" key={team.id}>
                    <div className="teams-label" style={{ background: "blue" }}>{team.name}</div>
                    <div className="item-container">
                        <TeamCheckBox
                            teamId={team.id}
                            userId={userId}
                            activeOrganization={activeOrganization}
                            selectedTeams={selectedTeams}
                            handleTeamSelection={handleTeamSelection}
                        />
                    </div>
                </div>
            ))}
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <button className="save-button" onClick={handleSave} disabled={saving}>
                    {saving ? 'Saving...' : <><MdSave />Save</>}
                </button>
            </div>
        </>
    );
}

function TeamCheckBox({ teamId, selectedTeams, handleTeamSelection }) {
    const checked = selectedTeams.includes(teamId);

    return (
        <input
            type="checkbox"
            onChange={() => handleTeamSelection(teamId)}
            id={teamId}
            checked={checked}
        />
    );
}
