import { createContext, useContext, useState } from 'react';

const UsersContext = createContext();

export const UsersProvider = ({ children }) => {
  const [users, setUsers] = useState([]);
  const [teams, setTeams] = useState([]);
  const [teamsModal, setTeamsModal] = useState(false);

  return (
    <UsersContext.Provider value={{ users, setUsers, teams, setTeams, teamsModal, setTeamsModal}}>
      {children}
    </UsersContext.Provider>
  );
};


export const useUsers = () =>  useContext(UsersContext);


const ArchiveContext = createContext();
export const ArchiveProvider = ({ children }) => {
  const [archiveModal, setArchiveModal] = useState(false);

  return (
    <ArchiveContext.Provider value={{ archiveModal, setArchiveModal }}>
      {children}
    </ArchiveContext.Provider>
  );
};

export const useArchive = () =>  useContext(ArchiveContext);


const UserTeamsContext = createContext();

export const UserTeamsProvider = ({ children }) => {
  const [userTeams, setUserTeams] = useState([]);

  return (
    <UserTeamsContext.Provider value={{ userTeams, setUserTeams }}>
      {children}
    </UserTeamsContext.Provider>
  );
};

export const useUserTeams = () =>  useContext(UserTeamsContext);
