import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./subnav.css";


export default function Subnav({ item }) {

  const [activeItem, setActiveItem] = useState('./');

  return (
    <>
      <Link className="SidebarLink" to={item.path}>
      </Link>
      {item.subNav.map((item, index) => {
        return (
          <>
            <Link onClick={() => setActiveItem(item.path)} className={`menu-item tooltip ${activeItem === item.path ? 'active-item' : ''}`} to={item.path} key={index}>
              {item.icon}
              <span className="tooltiptext">{item.title}</span>
            </Link>

          </>
        );
      })}
    </>
  );
} 