import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import AdminRoute from './utils/AdminRoute';
import WorkspaceRoute from './utils/WorkspaceRoute';
import { ClerkProvider, SignedIn, SignedOut, RedirectToSignIn } from "@clerk/clerk-react";

import LeftNavLayout from './layouts/left-navbar/left-nav';
import FullPageLayout from './layouts/full-page/full-page';

import Dashboard from './pages/dashboard/dashboard';
import Messages from './pages/messages/messages';
import Users from './pages/users/users';
import Sidebar from './pages/shared/sidebar/sidebar';
import InstallPage from './pages/install/install';
import PostInstallPage from './pages/post-install/post-install';
import RestrictedAccessPage from './pages/restricted-access/restricted-access';
import TCPage from './pages/terms-and-conditions/terms-and-conditions';
import PrivacyPage from './pages/privacy/privacy';
import WorkspaceSwitch from './pages/workspace-switch/workspace-switch'
import Integrations from './pages/integrations/integrations';
import { UsersProvider } from './contexts/users-context';
if (!process.env.REACT_APP_CLERK_PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key")
}
const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

const App = () => {

  return (
    <Router>
      <ClerkProvider publishableKey={clerkPubKey}>
        <Routes>
          {/* Public route accessible to all */}
          <Route path="/install" element={<InstallPage />} />
          <Route path="/privacy" element={<PrivacyPage />} />
          <Route path="/terms-and-conditions" element={<TCPage />} />
          <Route path="/successful-installation" element={<PostInstallPage />} />
          <Route path="/restricted-access" element={<RestrictedAccessPage />} />

          {/* Authenticated routes */}
          <Route
            path="/*"
            element={
              <>
                <SignedIn>
                  <Routes>
                    <Route
                      path="/*"
                      element={ <WorkspaceRoute element={
                        <LeftNavLayout
                          left={<Sidebar />}
                          right={
                            <Routes>
                              <Route index element={<Dashboard />} />
                              <Route path="/engagement" element={<Navigate to="/dashboard" />} />
                              <Route path="/dashboard" element={<Dashboard />} />
                              <Route path='/integrations' element={<Integrations />} />
                              <Route path="/messages" element={<AdminRoute element={<Messages />} />} />
                              <Route
                              path="/users"
                              element={<UsersProvider> <Users /> </UsersProvider>} />
                            </Routes>
                          }
                        />
                      }/>
                      }
                    />

                    <Route
                      path="/select-workspace"
                      element={
                        <FullPageLayout
                          content={<WorkspaceSwitch />}
                        />
                      }
                    />
                  </Routes>
                </SignedIn>
                <SignedOut>
                  <RedirectToSignIn />
                </SignedOut>
              </>
            }
          />
        </Routes>
      </ClerkProvider>
    </Router>
  );
};

export default App;