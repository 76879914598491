import { useState, useEffect } from "react";
import Counter from "./CounterPresentation"

export default function CounterContainer({ employeeesWeekData, category }) {
  const [totalCount, setTotalCount] = useState(0);
  const [categoryCount, setCategoryCount] = useState(0);

  useEffect(() => {
    const filteredEmployees = employeeesWeekData.filter(employee => employee.categories[category.name]);
    setCategoryCount(filteredEmployees.length);
    setTotalCount(employeeesWeekData.length);
  }, [employeeesWeekData, category.name]);

  // Prepare answer lines
  const answerLines = category.answer.split('\n');

  return (
    <Counter
      name={category.name}  
      title={category.title}
      helptext={category.helptext}
      categoryCount={categoryCount}
      totalCount={totalCount}
      color={category.color}
      label={category.label}
      question = {category.question}
      answerLines={answerLines}
      criteria={category.criteria}
    />
  );
}